// custom typefaces
import "@fontsource/montserrat/variable.css";
import "@fontsource/merriweather";
// normalize CSS across browsers
import "./src/normalize.scss";
// custom CSS styles
import "./src/style.scss";

// Highlighting for code blocks
import "prismjs/themes/prism.css";
require("prismjs/plugins/line-numbers/prism-line-numbers.css");
